import { useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { GET_VOTE_COMMENTS } from '../queries/queries'
import ResponsiveDialog from './ResponsiveDialog'

const styles = (theme) => ({})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const VoteCommentsDialog = ({ applicant, open, onClose }) => {
  const [filter, setFilter] = useState('noFilter')
  const { loading, error, data } = useQuery(GET_VOTE_COMMENTS, {
    variables: { applicantId: applicant.id },
  })

  let votes = null
  if (data && data.voteComments) {
    votes = data.voteComments
    if (filter !== 'noFilter') {
      votes = votes.filter((a) => a.choice === filter)
    }
  }
  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Vote comments from {applicant.name}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <InputLabel id='idfilter'>Filter by type of vote</InputLabel>
        <Select
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          labelId='idfilter'
          style={{ width: '20%' }}
        >
          <MenuItem value='noFilter'>Show All</MenuItem>
          <MenuItem value='No'>No</MenuItem>
          <MenuItem value='Negative'>Negative</MenuItem>
          <MenuItem value='Positive'>Positive</MenuItem>
          <MenuItem value='Yes'>Yes</MenuItem>
        </Select>
        <Spacer />
        {error && <Typography>Error</Typography>}
        {(loading || !data) && <CircularProgress />}
        {votes &&
          votes.map((comment) => (
            <div key={comment.id}>
              {comment.body && (
                <>
                  <Divider />
                  <Spacer />
                  <Typography variant='subtitle1'>
                    <i>
                      Comment by <strong>{comment.user.name}</strong>, who voted{' '}
                      <strong>{comment.choice}</strong>
                    </i>
                  </Typography>
                  <Typography variant='h6' style={{ paddingLeft: 16, paddingTop: 8 }}>
                    {comment.body}
                  </Typography>
                  <DoubleSpacer />
                </>
              )}
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default VoteCommentsDialog
