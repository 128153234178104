import React from 'react'
import PageTitle from './PageTitle'
import ParticipationTrackingTableDesktop from './ParticipationTrackingTableDesktop'
import { Grid } from '@material-ui/core'

const ParticipationTracking = () => {
  return (
    <React.Fragment>
      <PageTitle title='Participation Tracking' />
      <Grid container>
        <Grid item xs={false} lg={2} />
        <Grid item xs={12} lg={8}>
          <ParticipationTrackingTableDesktop />
        </Grid>
        <Grid item xs={false} lg={2} />
      </Grid>
    </React.Fragment>
  )
}

export default ParticipationTracking
