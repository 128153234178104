/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'es5-shim/es5-shim'
import 'es5-shim/es5-sham'
import 'es6-shim/es6-shim'
import 'es6-shim/es6-sham'
import 'es7-shim'
import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from '../src/components/App'

Sentry.init({ dsn: 'https://c9125a4ca1b147ea92e98a7d21645e70@o433796.ingest.sentry.io/5389753' })

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, document.body.appendChild(document.getElementById('application')))
})
