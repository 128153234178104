import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import React from 'react'
import PollResultsTableDesktop from './PollResultsTableDesktop'
import PollResultsTableMobile from './PollResultsTableMobile'

const PollResultsTable = (props) => {
  return isWidthUp('lg', props.width) ? (
    <PollResultsTableDesktop {...props} />
  ) : (
    <PollResultsTableMobile {...props} />
  )
}

export default withWidth()(PollResultsTable)
