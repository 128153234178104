import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import YesIcon from '@material-ui/icons/Check'
import NoIcon from '@material-ui/icons/Clear'
import MaybeIcon from '@material-ui/icons/HelpOutline'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CLOSE_POLL } from '../queries/mutations'
import { GET_POLL_RESULTS } from '../queries/queries'
import PageTitle from './PageTitle'
import ResponsiveDialog from './ResponsiveDialog'
import VoteCommentsDialog from './VoteCommentsDialog'
import PollResultsTable from './PollResultsTable'
import { MediumSpacer, MiniSpacer } from './Spacers'
import FeedbackListDialog from './FeedbackListDialog'

const alertStyles = (theme) => ({
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  buttonBackground: {
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    height: 75,
    backgroundColor: '#222',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(3),
    borderRadius: 100,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  yesIcon: {
    fontSize: 32,
    color: '#00ff99',
  },
  noIcon: {
    fontSize: 32,
    color: '#ff0066',
  },
  maybeIcon: {
    fontSize: 32,
    color: '#ffff99',
  },
})

const AlertDialog = withRouter(
  withStyles(alertStyles)(({ classes, history, open, onClose }) => {
    const [closePoll] = useMutation(CLOSE_POLL)

    return (
      <ResponsiveDialog open={open} onClose={onClose}>
        <DialogTitle>WARNING</DialogTitle>
        <DialogContent>
          <Typography variant='h6'>
            Closing the poll will prevent you from accessing its data anymore.
            <br />
            <br />
            Close the poll only once the process of member invitation is finished.
            <br />
            <br />
            <br />
            Are you sure you want to close this poll?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dangerButton}
            onClick={() =>
              closePoll({
                variables: {
                  input: {},
                },
              }).then(() => history.push('/admin'))
            }
          >
            Close Poll
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </ResponsiveDialog>
    )
  })
)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: 'center',
  },
  body: {
    fontSize: 16,
    textAlign: 'center',
  },
}))(TableCell)
const StyledUserTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell)

const PollResults = ({ classes, width }) => {
  const [alertDialog, setAlertDialog] = useState(false)
  const [commentsDialog, setCommentsDialog] = useState(null)
  const [feedbackDialog, setFeedbackDialog] = useState(false)

  return (
    <React.Fragment>
      <PageTitle title='Poll Results' />
      <div className={classes.root}>
        <Grid
          container
          spacing={1}
          className={classes.grid}
          justify='space-around'
          alignItems='center'
        >
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.buttonBackground} onClick={() => setAlertDialog(true)}>
              <Typography variant='h4'>Close Poll</Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.buttonBackground} onClick={() => setFeedbackDialog(true)}>
              <Typography variant='h4'>View feedback</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <MiniSpacer />
      <PollResultsTable withResults setCommentsDialog={setCommentsDialog} />
      {alertDialog && <AlertDialog open={alertDialog} onClose={() => setAlertDialog(false)} />}
      {!!commentsDialog && (
        <VoteCommentsDialog
          open={!!commentsDialog}
          applicant={commentsDialog}
          onClose={() => setCommentsDialog(null)}
        />
      )}
      <FeedbackListDialog open={feedbackDialog} onClose={() => setFeedbackDialog(false)} />
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(PollResults)))
