import { Grid, Typography, Button, Divider } from '@material-ui/core'
import React from 'react'
import { MediumSpacer, MiniSpacer } from './Spacers'
import { getVoteLabel } from '../consts/voteLabels'

const VoteReview = ({ votePages, goToPage }) => {
  return (
    <>
      <Typography variant='h6'>
        These are your votes! Feel free to go back and change them if you've made a mistake!
      </Typography>
      <MediumSpacer />
      {votePages.map((page, i) => (
        <div key={page.id}>
          <Grid container alignItems='center'>
            <Grid item xs={4}>
              <Typography variant='h6'>{page.applicantName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6'>{getVoteLabel(page.vote.choice)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button onClick={() => goToPage(i)} variant='outlined' color='secondary'>
                Go back
              </Button>
            </Grid>
          </Grid>
          <MiniSpacer />
          <Divider />
          <MiniSpacer />
        </div>
      ))}
    </>
  )
}

export default VoteReview
