import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import { withRouter } from 'react-router-dom'

import ResponsiveDialog from './ResponsiveDialog'
import {
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Radio,
  FormControlLabel,
  RadioGroup,
  Typography,
  Button,
  IconButton,
  Grid,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Mutation, Query, useQuery } from '@apollo/client'
import { GET_APPLICANT_TESTIMONIALS, GET_FEEDBACKS } from '../queries/queries'
import ApplicationReviewContent from './ApplicationReviewContent'
import TestimonialDialog from './TestimonialDialog'
import useCurrentSession from './useCurrentSession'
import { MediumSpacer, SuperSpacer, MiniSpacer } from './Spacers'

const styles = (theme) => ({})

const FeedbackListDialog = ({ open, onClose }) => {
  const { loading, error, data } = useQuery(GET_FEEDBACKS)

  let feedbacks = null
  if (data && data.feedbacks) {
    feedbacks = data.feedbacks
  }
  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h5' noWrap color='secondary'>
              Feedback for the latest poll
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {error && <Typography>Error</Typography>}
        {(loading || !data) && <CircularProgress />}
        {feedbacks && feedbacks.length === 0 && <Typography>Nothing to show</Typography>}
        {feedbacks &&
          feedbacks.map((feedback) => (
            <div key={feedback.id}>
              {feedback.body && (
                <>
                  <Divider />
                  <MiniSpacer />
                  <Typography variant='subtitle1'>
                    <i>
                      Feedback by <strong>{feedback.user.name}</strong>
                    </i>
                  </Typography>
                  <Typography variant='h6' style={{ paddingLeft: 16, paddingTop: 8 }}>
                    {feedback.body}
                  </Typography>
                  <SuperSpacer />
                </>
              )}
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default FeedbackListDialog
