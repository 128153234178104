import { useQuery } from '@apollo/client'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { GET_POLL } from '../queries/queries'
import FillApplicationDialog from './FillApplicationDialog'
import OngoingPoll from './OngoingPoll'
import PageTitle from './PageTitle'
import ReadyForPoll from './ReadyForPoll'
import useCurrentSession from './useCurrentSession'
import { MiniSpacer, MediumSpacer } from './Spacers'

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexGrow: 1,
    maxHeight: 90,
  },
  grid: {
    textAlign: 'center',
    maxHeight: 90,
  },
  buttonBackground: {
    width: '100%',
    height: 100,
    backgroundColor: '#222',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    borderRadius: 100,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
})

const Home = ({ classes, width, history }) => {
  const currentSession = useCurrentSession()
  const [fillApplicationDialog, setFillApplicationDialog] = useState(false)
  const [pollDialog, setPollDialog] = useState(false)
  const { loading, error, data, refetch } = useQuery(GET_POLL)

  return (
    <React.Fragment>
      <PageTitle title='Home' />
      <div className={classes.root}>
        <Grid container spacing={1} className={classes.grid} justify='center' alignItems='center'>
          {currentSession.user.isApplicant && (
            <div
              className={classes.buttonBackground}
              onClick={() => setFillApplicationDialog(true)}
            >
              <Typography variant='h3'>Fill application</Typography>
            </div>
          )}
          {currentSession.user.isMember &&
            data &&
            data.poll &&
            data.poll.limit * 1000 > Date.now() && (
              <div className={classes.buttonBackground} onClick={() => setPollDialog(true)}>
                <Typography variant='h3'>Vote !</Typography>
              </div>
            )}
          {currentSession.user.isMember && !currentSession.user.isAdmin && (!data || !data.poll) && (
            <>
              <Typography variant='h5'>
                These fluffs are making it to the next poll! You can leave a testimonial about them
                for everyone else to see when they vote!
                <br />
                <br />
              </Typography>
              <ReadyForPoll />
            </>
          )}
          {currentSession.user.isAdmin && (
            <div className={classes.buttonBackground} onClick={() => history.push('/admin')}>
              <Typography variant='h3'>Admin Panel</Typography>
            </div>
          )}
        </Grid>
      </div>
      {fillApplicationDialog && (
        <FillApplicationDialog
          open={fillApplicationDialog}
          onClose={() => setFillApplicationDialog(false)}
        />
      )}
      {pollDialog && data.poll && (
        <OngoingPoll
          open={pollDialog}
          refetch={refetch}
          onClose={() => setPollDialog(false)}
          poll={data.poll}
        />
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(Home)))
