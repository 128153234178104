import { AppBar, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import * as React from 'react'
import { MainContext } from '../contexts/MainContext'
import ProfileMenu from './ProfileMenu'
import { MiniSpacer } from './Spacers'
import StormyDialog from './StormyDialog'

const styles = (theme) => ({
  appBarRoot: {
    height: 50,
    flexGrow: 1,
  },
  toolFlex: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
  },
  menuButton: {
    color: 'white',
    width: '40px',
    height: '40px',
  },
  menuIcon: {
    color: 'white',
    width: '30px',
    height: '30px',
  },
})

const Layout = ({ classes }) => {
  const { pageTitle } = React.useContext(MainContext)
  const [stormyDialog, setStormyDialog] = React.useState(false)

  return (
    <React.Fragment>
      <AppBar className={classes.appBarRoot} position='static'>
        <Toolbar className={classes.toolFlex} variant='dense'>
          <ProfileMenu />
          <Typography variant='h5'>{pageTitle}</Typography>
          <IconButton className={classes.menuButton} onClick={() => setStormyDialog(true)}>
            <HelpIcon className={classes.menuIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MiniSpacer />
      <StormyDialog open={stormyDialog} onClose={() => setStormyDialog(false)} />
    </React.Fragment>
  )
}

export default withStyles(styles)(Layout)
