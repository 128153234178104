import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  withStyles,
  ListItemIcon,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import { MiniSpacer } from './Spacers'
import { PetsOutlined } from '@material-ui/icons'

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
  divider: {
    width: '80%',
    backgroundColor: theme.palette.primary.main,
  },
  dividerContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  darkDivider: {
    backgroundColor: theme.palette.primary.dark,
    width: '20%',
  },
})

const TermsDialog = ({ classes, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth scroll='body'>
        <DialogTitle>
          <Grid container spacing={0} alignItems='center' justify='space-between'>
            <Grid item>
              <Typography variant='h6' noWrap color={'inherit'}>
                Terms & Conditions
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <Typography variant='h5'>
                By using this website you agree to the following:
              </Typography>
            </ListItem>
            <MiniSpacer />
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </div>
            <MiniSpacer />
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>You agree to our privacy policy.</Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                Hate speech, harassment, threats, defamation are forbidden; this applies for the
                applications, votes, testimonials and anywhere else the website provides textfields.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                As an applicant, you won't upload any offensive picture or video, nor any material
                deemed illegal under French law.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                We reserve the right to remove access to any user, for any reason or no reason, and
                without any notice.
              </Typography>
            </ListItem>
            <MiniSpacer />
            <div className={classes.dividerContainer}>
              <Divider className={classes.darkDivider} />
            </div>
            <MiniSpacer />
            <ListItem>
              <Typography variant='h6'>
                This website is hosted in France, and as such is GDPR compliant. You can make a
                request at anytime for us to show you all the data we hold about yourself. You can
                also request for us to completely get rid of it. For that, simply contact the
                developer via this link:{' '}
                <a href='https://t.me/LeStormy' className={classes.link}>
                  Contact on Telegram
                </a>
              </Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button size='large' onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(TermsDialog)
