import React, { createContext, useReducer } from 'react'
import { initialState, reducer } from '../services/mainReducer'

const MainContext = createContext()

const MainContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <MainContext.Provider
      value={{
        pageTitle: state.pageTitle,
        dispatch,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export { MainContext, MainContextProvider }
