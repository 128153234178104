import { useQuery } from '@apollo/client'
import { Card, CardContent, CircularProgress, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import withWidth from '@material-ui/core/withWidth'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { GET_APPLICANTS } from '../queries/queries'

const styles = (theme) => ({})

const Spacer = () => <div style={{ padding: 32 }} />

const AuthorizedUsers = ({ width }) => {
  const { data, loading, error } = useQuery(GET_APPLICANTS, { variables: { status: 'pending' } })

  if (loading) return <CircularProgress />
  if (error) return <Typography variant='h6'>ERROR</Typography>

  const authorized = data.applicants
  if (authorized.length === 0) return <Typography variant='h6'>None</Typography>

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {authorized.map((item) => (
          <Grid item xs={6} lg={4} key={item.id}>
            <Card>
              <CardContent>
                <Typography variant='subtitle1'>
                  {item.telegramUsername}
                  <br />
                  (id: {item.telegramId})
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default withRouter(withWidth()(AuthorizedUsers))
