import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import YesIcon from '@material-ui/icons/Check'
import NoIcon from '@material-ui/icons/Clear'
import MaybeIcon from '@material-ui/icons/HelpOutline'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CLOSE_POLL } from '../queries/mutations'
import { GET_POLL_RESULTS, GET_POLL_RESULTS_MID_POLL } from '../queries/queries'
import PageTitle from './PageTitle'
import ResponsiveDialog from './ResponsiveDialog'
import VoteCommentsDialog from './VoteCommentsDialog'
import { MediumSpacer } from './Spacers'
import { voteLabels } from '../consts/voteLabels'

const alertStyles = (theme) => ({
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexGrow: 1,
    maxHeight: 90,
  },
  grid: {
    textAlign: 'center',
    maxHeight: 90,
  },
  buttonBackground: {
    width: '40%',
    height: 75,
    backgroundColor: '#222',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    borderRadius: 100,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  yesIcon: {
    fontSize: 32,
    color: '#00ff99',
  },
  noIcon: {
    fontSize: 32,
    color: '#ff0066',
  },
  maybeIcon: {
    fontSize: 32,
    color: '#ffff99',
  },
})

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: 'center',
  },
  body: {
    fontSize: 16,
    textAlign: 'center',
  },
}))(TableCell)
const StyledUserTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell)

const PollResultsTable = ({ classes, withResults, setCommentsDialog }) => {
  const { loading, error, data } = useQuery(
    withResults ? GET_POLL_RESULTS : GET_POLL_RESULTS_MID_POLL
  )

  if (loading) return <CircularProgress />
  if (error || !data || !data.pollResults) return <Typography>ERROR</Typography>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledUserTableCell>User</StyledUserTableCell>
          {voteLabels.map((label) => (
            <StyledTableCell key={label.id}>{label.label}</StyledTableCell>
          ))}
          {withResults && <StyledTableCell>Result</StyledTableCell>}
          {withResults && <StyledTableCell>View Comments</StyledTableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.pollResults.map((result) => (
          <TableRow key={result.id}>
            <StyledUserTableCell>
              {result.applicant.name}
              <br />@{result.applicant.telegramUsername}
            </StyledUserTableCell>
            <StyledTableCell>{result.no}</StyledTableCell>
            <StyledTableCell>{result.negative}</StyledTableCell>
            <StyledTableCell>{result.positive}</StyledTableCell>
            <StyledTableCell>{result.yes}</StyledTableCell>
            {withResults && (
              <StyledTableCell>
                {result.result === 'Yes' ? (
                  <YesIcon className={classes.yesIcon} />
                ) : result.result === 'No' ? (
                  <NoIcon className={classes.noIcon} />
                ) : (
                  <MaybeIcon className={classes.maybeIcon} />
                )}
              </StyledTableCell>
            )}
            {withResults && (
              <StyledTableCell>
                <Button variant='outlined' onClick={() => setCommentsDialog(result.applicant)}>
                  Comments
                </Button>
              </StyledTableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default withStyles(styles)(PollResultsTable)
