import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import apolloClient from '../apolloClient'
import AppRouter from './AppRouter'

const makeTheme = (type) => {
  const background = { paper: '#222222', default: '#000000' }

  return createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: `'Sarabun', sans-serif;`,
      fontWeight: 300,
      h1: {
        fontFamily: `'Londrina Solid', cursive;`,
      },
      h2: {
        fontFamily: `'Londrina Solid', cursive;`,
      },
      h3: {
        fontFamily: `'Londrina Solid', cursive;`,
      },
      h4: {
        fontFamily: `'Londrina Solid', cursive;`,
        fontWeight: 100,
        fontSize: '2rem',
      },
      h5: {
        fontFamily: `'Londrina Solid', cursive;`,
        fontWeight: 300,
        fontSize: '1.75rem',
      },
      h6: {
        fontWeight: 300,
        fontSize: '1.5rem',
      },
      subtitle1: {
        fontWeight: 300,
        fontSize: '1.25rem',
      },
      subtitle2: {
        fontWeight: 300,
        fontSize: '1.10rem',
      },
      body1: {
        fontWeight: 500,
        fontSize: '1.10rem',
      },
      caption: {
        fontWeight: 500,
        fontSize: '1rem',
      },
    },
    html: {
      fontSize: '16px',
      fontWeight: 200,
    },
    palette: {
      background,
      primary: {
        main: '#858DFF',
      },
      secondary: {
        main: '#FFE06B',
      },
      danger: {
        main: '#FF3D3B',
      },
      type: type,
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '1em',
        },
      },
      MuiButton: {
        root: {
          fontSize: '1.25rem',
        },
      },
      MuiInputBase: {
        root: {
          '& input': {
            fontWeight: 300,
          },
        },
        input: {
          fontWeight: 300,
        },
      },
      MuiSelect: {
        root: {
          '& input': {
            fontWeight: 300,
          },
        },
      },
    },
  })
}

export default () => {
  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={makeTheme('dark')}>
        <CssBaseline />
        <AppRouter />
      </MuiThemeProvider>
    </ApolloProvider>
  )
}
