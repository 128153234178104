import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItem,
  Popover,
  Typography,
  withStyles,
} from '@material-ui/core'
import { AccountBox as UserIcon } from '@material-ui/icons'
import React from 'react'
import withRouter from 'react-router-dom/withRouter'
import { DELETE_SESSION } from '../queries/globalQueries'
import useCurrentSession from './useCurrentSession'
import MyAccountDialog from './MyAccountDialog'
// import MyAccountDialog from "./MyAccountDialog";

const styles = (theme) => ({
  typoName: {
    marginLeft: theme.spacing(2.4),
  },
  darkBackground: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  menuButton: {
    color: 'white',
    width: '40px',
    height: '40px',
  },
  menuIcon: {
    color: 'white',
    width: '30px',
    height: '30px',
  },
})

const ProfileMenu = ({ classes, history }) => {
  const { user } = useCurrentSession() || {}
  const [open, setOpen] = React.useState(false)
  const [accountDialogOpen, setAccountDialogOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  //   const signOutLoading = false; // TODO MUTATION
  // const { dispatch } = React.useContext(MainContext);
  const [signOut, { loading: signOutLoading }] = useMutation(DELETE_SESSION, {
    onCompleted: () => {
      history.push('/')
      location.reload()
    },
  })

  if (!user) {
    return null
  }

  return (
    <React.Fragment>
      <IconButton
        className={classes.menuButton}
        onClick={(e) => {
          setAnchorEl(e.target)
          setOpen(true)
        }}
      >
        <UserIcon className={classes.menuIcon} />
      </IconButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 45, horizontal: 'left' }}
      >
        <ListItem>
          <Typography variant='h6' className={classes.typoName} display='inline'>
            Logged in as&nbsp;&nbsp;
          </Typography>
          <Typography display='inline' variant='h5'>
            {user.name}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem>
          <Box display='flex' flexGrow='1' justifyContent='space-between'>
            <Button
              variant='contained'
              className={classes.darkBackground}
              onClick={() => {
                setOpen(false)
                setAccountDialogOpen(true)
              }}
            >
              <Typography variant='body1'>My account</Typography>
            </Button>
            {signOutLoading ? (
              <CircularProgress />
            ) : (
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => signOut({ variables: { input: {} } })}
              >
                <Typography variant='body1'>Logout</Typography>
              </Button>
            )}
          </Box>
        </ListItem>
      </Popover>
      <MyAccountDialog open={accountDialogOpen} onClose={() => setAccountDialogOpen(false)} />
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(ProfileMenu))
