import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ResponsiveDialog from './ResponsiveDialog'
import VotePage from './VotePage'
import { GET_VOTE_PAGES } from '../queries/queries'
import { useQuery } from '@apollo/client'
import VoteReview from './VoteReview'
import goldStar from '../../../assets/images/goldStar.png'
import { MediumSpacer, MiniSpacer } from './Spacers'
import FeedbackPage from './FeedbackPage'
import VoteBlurb from './VoteBlurb'

const styles = (theme) => ({
  centerImage: {
    textAlign: 'center',
  },
  image: {
    width: '80%',
  },
})

const END_VOTES_PAGE = 100
const EXTRA_QUESTIONS_PAGE = 200
const EXTRA_QUESTIONS_OVER_PAGE = 300
const FEED_BACK_PAGE = 400
const FEED_BACK_PAGE_OVER = 500

const OngoingPoll = ({ open, onClose, poll, classes }) => {
  const [page, setPage] = useState(-1)
  const { data, loading, error } = useQuery(GET_VOTE_PAGES, { onError: (e) => console.log(e) })

  if (loading || error || !data || !data.votePages) return null //TODO
  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Ongoing Poll
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {page === -1 && (
        <React.Fragment>
          <DialogContent>
            <VoteBlurb />
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={() => setPage(0)}>
              Start Voting
            </Button>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </React.Fragment>
      )}
      {page > -1 && page < data.votePages.length && (
        <VotePage
          next={() => setPage(page + 1)}
          prev={() => setPage(page - 1)}
          votePage={data.votePages[page]}
          pollId={poll.id}
        />
      )}
      {page === data.votePages.length && (
        <>
          <DialogContent>
            <VoteReview votePages={data.votePages} goToPage={(e) => setPage(e)} />
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={() => setPage(END_VOTES_PAGE)}>
              Looks All Good!
            </Button>
          </DialogActions>
        </>
      )}
      {page === END_VOTES_PAGE && (
        <>
          <DialogContent>
            <Typography variant='subtitle1'>
              Thank you! All your votes are now saved.
              {/* Do you wanna go for some extra questions? */}
            </Typography>
            <Typography variant='subtitle1'>
              You can also leave a feedback for this poll at the bottom.
              {/* (The feedback option is also available after the extra questions) */}
            </Typography>
            <MiniSpacer />
            <Typography variant='subtitle1'>Anyway here's your freakin GOLD STAR 😒</Typography>
            <div className={classes.centerImage}>
              <img className={classes.image} src={goldStar}></img>
            </div>
          </DialogContent>
          <DialogActions>
            {/* <Button
              variant='contained'
              color='primary'
              onClick={() => setPage(EXTRA_QUESTIONS_PREFIX + 0)}
            >
              More questions!
            </Button> */}
            <Button onClick={() => setPage(FEED_BACK_PAGE)}>Feedback</Button>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      )}
      {page === FEED_BACK_PAGE && <FeedbackPage next={() => setPage(FEED_BACK_PAGE_OVER)} />}
      {page === FEED_BACK_PAGE_OVER && (
        <>
          <DialogContent>
            <Typography variant='subtitle1'>Thank you for your feedback</Typography>
            <Typography variant='subtitle1'>
              You can now leave this page. If you wish to edit your votes at some point, you just
              need to come back here!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      )}
      {page === EXTRA_QUESTIONS_PAGE && (
        <>{/* TODO <ExtraQuestions finish={() => setPage(EXTRA_QUESTIONS_OVER_PAGE)}/> */}</>
      )}
      {page === EXTRA_QUESTIONS_OVER_PAGE && (
        <>
          <DialogContent>
            <Typography variant='subtitle1'>Thank you for your answers!</Typography>
            <Typography variant='subtitle1'>
              You can now leave this page. If you wish to edit your votes at some point, you just
              need to come back here! You can still give some feedback on the poll or the extra
              questions too if you wish!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPage(FEED_BACK_PAGE)}>Feedback</Button>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      )}
    </ResponsiveDialog>
  )
}

export default withStyles(styles)(withRouter(withWidth()(OngoingPoll)))
