import React from 'react'
import ResponsiveDialog from './ResponsiveDialog'
import TelegramLogin from './TelegramLogin'

const styles = (theme) => ({})

const SignUpDialog = ({ open, onClose }) => {
  return (
    <React.Fragment>
      <ResponsiveDialog
        open={open}
        onClose={() => {
          onClose()
        }}
      >
        <TelegramLogin onClose={onClose} />
      </ResponsiveDialog>
    </React.Fragment>
  )
}

export default SignUpDialog
