import { Query, useMutation, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import DownIcon from '@material-ui/icons/ArrowDownward'
import CloseIcon from '@material-ui/icons/Close'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import React from 'react'
import Dropzone from 'react-dropzone'
import { withRouter } from 'react-router-dom'
import { CREATE_MEDIUM } from '../queries/mutations'
import { GET_APPLICANT_MEDIA } from '../queries/queries'
import Medium from './Medium'

const dropZoneStyles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: 2,
    textAlign: 'center',
    color: 'white',
    background: theme.palette.primary.light,
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    translation: 'opacity 0.5s ease',
    overflow: 'hidden',
  },
  uploadIcon: {
    fontSize: '4em',
  },
  progress: {
    color: 'white',
  },
})

const styles = (theme) => ({
  dangerButton: {
    color: theme.palette.danger.main,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
})

const processFileName = (file) =>
  file.name
    .replace(/\.[^.]+$/, '')
    .replace(/[^a-zA-Z0-9]+/, ' ')
    .split(' ')
    .filter((word) => word.length > 0)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

class DropZoneField extends React.Component {
  state = {
    progress: null,
    disabled: false,
    file: null,
    uploaded: false,
  }

  handleDrop(files) {
    if (this.state.disabled) {
      return
    }
    var loadedFiles = []
    this.setState({ uploading: true })
    this.setState({ disabled: true })
    const pushFile = (index) => {
      loadedFiles.push(files[index])
      if (files[index + 1]) {
        pushFile(index + 1)
      } else {
        this.setState({ uploaded: true, uploading: false })
        this.props.onLoaded(loadedFiles)
        this.props.onComplete()
      }
    }
    pushFile(0)
    this.props.onStart()
  }

  render() {
    const { classes, width, dropzoneDisabled } = this.props

    return (
      <Dropzone
        multiple={true}
        className={classes.root}
        disabled={dropzoneDisabled}
        accept='image/png,image/x-png,image/jpeg,image/gif'
        style={{
          height: width === 'lg' || width === 'xl' ? 220 : 130,
          pointerEvents: this.state.disabled || dropzoneDisabled ? 'none' : 'auto',
          cursor: this.state.disabled || dropzoneDisabled ? 'no-drop' : 'pointer',
        }}
        onDrop={(files) => this.handleDrop(files)}
      >
        {this.state.uploaded && !this.props.pushing && !this.props.pushed && (
          <div>
            <Typography variant='h6' color='inherit' noWrap>
              Media ready for upload.
            </Typography>
          </div>
        )}
        {this.state.uploaded && this.props.pushing && !this.props.pushed && (
          <div>
            <Typography variant='h6' color='inherit' noWrap>
              Pushing to Server
            </Typography>
          </div>
        )}
        {this.state.uploaded && !this.props.pushing && this.props.pushed && (
          <div>
            <Typography variant='h6' color='inherit' noWrap>
              All pictures were successfuly uploaded
            </Typography>
          </div>
        )}
        {this.state.uploading && (
          <div>
            <CircularProgress
              className={classes.progress}
              style={{
                marginBottom: width === 'lg' || width === 'xl' ? 16 : 0,
              }}
            />
          </div>
        )}
        {this.props.pushing && this.props.currentFile && (
          <div>
            <CircularProgress
              className={classes.progress}
              style={{
                marginBottom: width === 'lg' || width === 'xl' ? 16 : 0,
              }}
            />
            <Typography variant='caption' color='inherit' noWrap>
              {this.props.currentFile.name}
            </Typography>
          </div>
        )}
        {dropzoneDisabled && !this.state.uploaded && !this.state.uploading && (
          <div>
            <CloudUploadIcon
              className={classes.uploadIcon}
              style={{
                marginBottom: width === 'lg' || width === 'xl' ? 16 : 0,
              }}
            />
            <Typography variant='h6' color='inherit'>
              {width === 'lg' || width === 'xl'
                ? 'Select from the above dropdowns before dragging media into this area or clicking this area'
                : 'Click here to upload media after selecting dropdowns'}
            </Typography>
          </div>
        )}
        {!dropzoneDisabled && !this.state.uploaded && !this.state.uploading && (
          <div>
            <CloudUploadIcon
              className={classes.uploadIcon}
              style={{
                marginBottom: width === 'lg' || width === 'xl' ? 16 : 0,
              }}
            />
            <Typography variant='h6' color='inherit' noWrap>
              {width === 'lg' || width === 'xl'
                ? 'Select or drag media files to upload'
                : 'Select media files to upload'}
            </Typography>
          </div>
        )}
      </Dropzone>
    )
  }
}

const DropZoneFieldWithStyle = withStyles(dropZoneStyles)(withWidth()(DropZoneField))

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const ApplicationPartTwo = ({
  classes,
  uploadEnabled,
  setAlertDialog,
  alertDialog,
  hasMedia,
  applicant,
  goToReview,
  goToMediaAgain,
  reload,
}) => {
  const [media, setMedia] = React.useState([...applicant.media])
  const [files, setFiles] = React.useState([])
  const [file, setFile] = React.useState(null)
  const [currentFile, setCurrentFile] = React.useState(null)
  const [pushed, setPushed] = React.useState(false)
  const [pushing, setPushing] = React.useState(false)
  const [uploaded, setUploaded] = React.useState(false)
  const [complete, setComplete] = React.useState(false)
  const [uploading, setUploading] = React.useState(false)

  const [createMedium] = useMutation(CREATE_MEDIUM, {
    update: (cache, { data: { createMedium } }) => {
      const { applicantMedia } = cache.readQuery({
        query: GET_APPLICANT_MEDIA,
        variables: { applicantId: applicant.id },
      })
      cache.writeQuery({
        query: GET_APPLICANT_MEDIA,
        variables: { applicantId: applicant.id },
        data: {
          applicantMedia: [createMedium.medium, ...applicantMedia],
        },
      })
    },
  })

  const {
    data: applicantMediaQueryData,
    loading: applicantMediaQueryLoading,
    error: applicantMediaQueryError,
  } = useQuery(GET_APPLICANT_MEDIA, {
    variables: { applicantId: applicant.id },
  })

  const loadFiles = () => {
    const goThroughFiles = (index) => {
      setFile(files[index])
      setUploading(true)

      const sendFile = (result) =>
        createMedium({
          variables: {
            input: {
              file: result,
              applicantId: applicant.id,
            },
          },
        })

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setCurrentFile(files[index])
        sendFile(reader.result).then(() => {
          if (files[index + 1]) {
            goThroughFiles(index + 1)
          } else {
            setPushed(true)
            setPushing(false)
          }
        })
      })
      reader.readAsDataURL(files[index])
    }
    goThroughFiles(0)
    setPushing(true)
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Add Media to Application
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={() => setAlertDialog(true)} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant='subtitle1'>
          At least one picture is required. You can upload has many pictures or videos as you want.
          <br />
          For videos, we ask that you keep the files you upload to a reasonable size (100M at most).
          <br />
          Pictures can be anything you like, SFW to XXX. A mix is recommended.
        </Typography>
        <DropZoneFieldWithStyle
          dropzoneDisabled={false}
          pushing={pushing}
          pushed={pushed}
          onStart={() => {}}
          onLoaded={(payload) => setFiles(payload)}
          onComplete={() => setComplete(true)}
          currentFile={currentFile}
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={0} justify='space-between'>
          <Grid item />
          <Grid item>
            {!pushed && (
              <Button
                color='secondary'
                disabled={!complete || pushing || pushed}
                onClick={() => loadFiles()}
              >
                Send Pictures
              </Button>
            )}
            {pushed && <Button onClick={goToMediaAgain}>Send More Pics!</Button>}
            {(hasMedia || pushed) &&
              applicantMediaQueryData &&
              applicantMediaQueryData.applicantMedia.length > 0 && (
                <Button onClick={goToReview}>I'm done with Media</Button>
              )}
            <Button
              className={classes.dangerButton}
              onClick={() => {
                setAlertDialog(true)
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogContent>
        <Typography variant='h4'>&nbsp;&nbsp;Uploaded</Typography>
        <Typography variant='subtitle1'>
          These are the pictures you added to your application already
        </Typography>
      </DialogContent>
      <Spacer />
      {applicantMediaQueryLoading && <CircularProgress />}
      {(applicantMediaQueryError || !applicantMediaQueryData) && (
        <Typography variant='h6'>ERROR FETCHING MEDIA</Typography>
      )}
      {applicantMediaQueryData && applicantMediaQueryData.applicantMedia && (
        <DialogContent>
          <Grid container spacing={1}>
            {applicantMediaQueryData.applicantMedia &&
              applicantMediaQueryData.applicantMedia.map((medium) => (
                <Grid xs={12} md={6} item key={medium.id}>
                  <Medium medium={medium} deletable applicantId={applicant.id} />
                </Grid>
              ))}
          </Grid>
        </DialogContent>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(ApplicationPartTwo)))
