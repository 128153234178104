import { Button, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import LoginDialog from './LoginDialog'
import PrivacyPolicyDialog from './PrivacyPolicyDialog'
import { MediumSpacer, MiniSpacer } from './Spacers'
import TermsDialog from './TermsDialog'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  legalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  legalButton: {
    width: 200,
  },
})

const Login = ({ classes, width }) => {
  const [loginDialog, setLoginDialog] = useState(false)
  const [termsDialog, setTermsDialog] = useState(false)
  const [privacyDialog, setPrivacyDialog] = useState(false)

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paper}>
          <Typography variant='h3'>Shag Carpets</Typography>
          <MiniSpacer />
          <Typography variant='h6'>
            Welcome to Shag Carpet's voting app! If you are a member or an applicant, you can log in
            below!
          </Typography>
          <MiniSpacer />
          <Typography variant='h6'>
            By logging in, you agree to our terms and privacy policy, which can be displayed by
            clicking the following buttons.
            <br />
            We also use cookies to store a session ID so we can keep you logged in and allow you to
            use the website.
          </Typography>
          <MediumSpacer />
          <div className={classes.legalButtons}>
            <Button
              onClick={() => setTermsDialog(true)}
              className={classes.legalButton}
              variant='h3'
              variant='outlined'
              size='large'
              color='primary'
            >
              Terms & Conditions
            </Button>
            <MiniSpacer />
            <Button
              onClick={() => setPrivacyDialog(true)}
              className={classes.legalButton}
              variant='h3'
              variant='outlined'
              size='large'
              color='primary'
            >
              Privacy Policy
            </Button>
          </div>
          <MediumSpacer />
          <Button
            onClick={() => setLoginDialog(true)}
            variant='contained'
            size='large'
            color='primary'
          >
            Login With Telegram
          </Button>
        </Paper>
      </div>
      {loginDialog && <LoginDialog open={loginDialog} onClose={() => setLoginDialog(false)} />}
      {termsDialog && <TermsDialog open={termsDialog} onClose={() => setTermsDialog(false)} />}
      {privacyDialog && (
        <PrivacyPolicyDialog open={privacyDialog} onClose={() => setPrivacyDialog(false)} />
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(Login)))
