import { useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { GET_APPLICANT_BY_USER } from '../queries/queries'
import ApplicationPartFour from './ApplicationPartFour'
import ApplicationPartOne from './ApplicationPartOne'
import ApplicationPartThree from './ApplicationPartThree'
import ApplicationPartTwo from './ApplicationPartTwo'
import ResponsiveDialog from './ResponsiveDialog'
import useCurrentSession from './useCurrentSession'
import { MediumSpacer } from './Spacers'

const styles = (theme) => ({
  danger: {
    color: theme.palette.danger.main,
  },
})

const AlertDialog = ({ classes, open, onClose, confirmClose }) => {
  return (
    <ResponsiveDialog open={open} onClose={onClose} size={300}>
      <DialogTitle className={classes.danger}>Are you sure?!</DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          All changes will not be saved, are you sure you want to cancel?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmClose}>YES</Button>
        <Button onClick={onClose}>NO</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

const FillApplicationDialog = ({ classes, open, onClose }) => {
  const currentSession = useCurrentSession()
  const [alertDialog, setAlertDialog] = useState(false)
  const [reload, setReload] = useState(false)
  const [part, setPart] = useState(1)
  const { loading, error, data } = useQuery(GET_APPLICANT_BY_USER, {
    variables: { telegramId: currentSession?.user?.telegramId },
  })

  if (part !== 4 && data && data.applicantByUser.status != 'pending')
    return (
      <React.Fragment>
        <ResponsiveDialog
          open={open}
          onClose={() => {
            onClose()
          }}
        >
          <DialogTitle>
            <Grid container spacing={0} alignItems='center' justify='space-between'>
              <Grid item>
                <Typography variant='h6' noWrap color={'inherit'}>
                  Under Review
                </Typography>
              </Grid>
              <Grid item>
                <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          {loading && <CircularProgress />}
          {error && <Typography>ERROR</Typography>}
          <DialogContent>
            <Typography variant='subtitle1'>
              Your application is being reviewed by our admins
            </Typography>
          </DialogContent>
          <MediumSpacer />
        </ResponsiveDialog>
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <ResponsiveDialog
        open={open}
        onClose={() => {
          onClose()
        }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        {loading && <CircularProgress />}
        {error && <Typography>ERROR</Typography>}
        {!loading && !error && data && data.applicantByUser && part === 1 && (
          <ApplicationPartOne
            alertDialog={alertDialog}
            setAlertDialog={setAlertDialog}
            applicant={data.applicantByUser}
            goToPictures={(e) => {
              setPart(2)
            }}
          />
        )}
        {!loading && !error && data && data.applicantByUser && part === 2 && (
          <ApplicationPartTwo
            applicant={data.applicantByUser}
            alertDialog={alertDialog}
            setAlertDialog={setAlertDialog}
            hasMedia={data.applicantByUser.media && data.applicantByUser.media.length}
            reload={reload}
            goToReview={() => setPart(3)}
            goToMediaAgain={() => setPart(10)}
          />
        )}
        {!loading && !error && data && data.applicantByUser && part === 10 && (
          <ApplicationPartTwo
            applicant={data.applicantByUser}
            alertDialog={alertDialog}
            hasMedia={data.applicantByUser.media && data.applicantByUser.media.length}
            setAlertDialog={setAlertDialog}
            goToReview={() => setPart(3)}
            goToMediaAgain={() => setPart(2)}
          />
        )}
        {!loading && !error && data && data.applicantByUser && part === 3 && (
          <ApplicationPartThree
            applicant={data.applicantByUser}
            goToInfo={() => setPart(1)}
            goToMedia={() => setPart(2)}
            allDone={() => setPart(4)}
            alertDialog={alertDialog}
            setAlertDialog={setAlertDialog}
          />
        )}
        {!loading && !error && data && data.applicantByUser && part === 4 && (
          <ApplicationPartFour applicant={data.applicantByUser} onClose={onClose} />
        )}
      </ResponsiveDialog>
      <AlertDialog
        classes={classes}
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        confirmClose={() => {
          setAlertDialog(false)
          onClose()
        }}
      />
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(FillApplicationDialog)))
