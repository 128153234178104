import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import classnames from 'classnames'
import { Typography, CircularProgress } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { GET_APPLICANT_MEDIA } from '../queries/queries'
import { DELETE_MEDIUM } from '../queries/mutations'

const styles = (theme) => ({
  medium: {
    width: '100%',
    position: 'relative',
  },
  hover: {
    width: '100%',
  },
  overlay: {
    textAlign: 'center',
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.6)',
    },
  },
  bigassText: {
    fontSize: '4rem',
  },
})

const Medium = ({ classes, medium, applicantId, deletable }) => {
  const [mouseIn, setMouseIn] = React.useState(false)
  const [deleteMedium, { loading }] = useMutation(DELETE_MEDIUM, {
    update: (cache, { data: { createMedium } }) => {
      const { applicantMedia } = cache.readQuery({
        query: GET_APPLICANT_MEDIA,
        variables: { applicantId: applicantId },
      })
      const newArray = applicantMedia.filter((a) => a.id != medium.id)
      cache.writeQuery({
        query: GET_APPLICANT_MEDIA,
        variables: { applicantId: applicantId },
        data: {
          applicantMedia: newArray,
        },
      })
    },
  })

  if (!deletable) return <img src={medium.file} className={classes.medium} />
  else
    return (
      <div className={classes.medium}>
        <img src={medium.file} className={classnames(classes.hover)} />
        <div
          onClick={() => {
            deleteMedium({ variables: { input: { id: medium.id } } })
          }}
          className={classes.overlay}
          onMouseEnter={() => setMouseIn(true)}
          onMouseLeave={() => setMouseIn(false)}
        >
          {mouseIn && <Typography className={classes.bigassText}>Click to delete</Typography>}
          {loading && <CircularProgress />}
        </div>
      </div>
    )
}

export default withStyles(styles)(Medium)
