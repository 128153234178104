import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  withStyles,
  ListItemIcon,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import { MiniSpacer } from './Spacers'
import { PetsOutlined } from '@material-ui/icons'

const styles = (theme) => ({
  divider: {
    width: '80%',
    backgroundColor: theme.palette.primary.main,
  },
  dividerContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  darkDivider: {
    backgroundColor: theme.palette.primary.dark,
    width: '20%',
  },
})

const PrivacyPolicyDialog = ({ classes, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth scroll='body'>
        <DialogTitle>
          <Grid container spacing={0} alignItems='center' justify='space-between'>
            <Grid item>
              <Typography variant='h6' noWrap color={'inherit'}>
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <Typography variant='h5'>
                We only store information for the purpose of providing our service. Those
                informations include:
              </Typography>
            </ListItem>
            <MiniSpacer />
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </div>
            <MiniSpacer />
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                Your Telegram username, ID, and display name. We cannot stress this enough, Telegram
                DOES NOT give out anyone's phone number.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                If you're an applicant, your application answers and media, from the moment they are
                submitted until up to 72 hours after the poll is over.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                All your answers and media are then destroyed automatically.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                For members, your testimonials and vote comments from the moment they are submitted
                until up to 72 hours after the poll is over. They are also destroyed automatically.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PetsOutlined />
              </ListItemIcon>
              <Typography variant='h6'>
                We also keep track of voting participation and, for this purpose, separately store
                your Telegram ID along with your past votes, with anonymized data for the voted
                applicant. We keep these for 3 to 6 months.
              </Typography>
            </ListItem>
            <MiniSpacer />
            <div className={classes.dividerContainer}>
              <Divider className={classes.darkDivider} />
            </div>
            <MiniSpacer />
            <ListItem>
              <Typography variant='h6'>
                Upon account deletion, all the informations related to your account are immediately
                deleted from our database.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h6'>
                Encrypted backups may persits this data for up to 7 days afterwards. Past this
                deadline, everything is gone! This also goes for the automatic deletion of data
                mentioned above.
              </Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button size='large' onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(PrivacyPolicyDialog)
