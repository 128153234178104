import { useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { CREATE_TESTIMONIAL } from '../queries/mutations'
import ResponsiveDialog from './ResponsiveDialog'

const styles = (theme) => ({
  separator: {
    textAlign: 'center',
    width: '80%',
  },
})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const TestimonialDialog = ({ open, applicant, onClose, classes, setTestified }) => {
  const [body, setBody] = useState('')
  const [createTestimonial, { loading: createTestimonialLoading }] = useMutation(CREATE_TESTIMONIAL)

  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item xs={11}>
            <Typography variant='h6' color={'inherit'}>
              Leave a testimonial for {applicant.name}'s application
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          Tell the other members about this applicant if you know them! It could help them get voted
          in!
        </Typography>
        <Spacer />
        <hr className={classes.separator} />
        <Spacer />
        <TextField
          label='Your Testimonial...'
          name='body'
          value={body}
          onChange={(e) => setBody(e.target.value)}
          margin='dense'
          fullWidth
          multiline
          rows={6}
          rowsMax={20}
        />
      </DialogContent>
      <DialogActions>
        {createTestimonialLoading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={() =>
              createTestimonial({
                variables: {
                  input: {
                    applicantId: applicant.id,
                    body,
                  },
                },
              }).then(({ data }) => {
                setTestified()
                onClose()
              })
            }
          >
            Send
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default withStyles(styles)(TestimonialDialog)
