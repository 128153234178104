import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  withStyles,
  Grid,
} from '@material-ui/core'
import { InfoOutlined, SecurityOutlined } from '@material-ui/icons'
import * as React from 'react'
import PrivacyPolicyDialog from './PrivacyPolicyDialog'
import { MiniSpacer } from './Spacers'
import TermsDialog from './TermsDialog'
import useCurrentSession from './useCurrentSession'

const styles = (theme) => ({
  flexGrid: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  separator: {
    width: '30%',
  },
  formControl: {
    width: '100%',
  },
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const MyAccountDialog = ({ classes, open, onClose }) => {
  const [termsDialogOpen, setTermsDialogOpen] = React.useState(false)
  const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = React.useState(false)
  const currentSession = useCurrentSession()

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth scroll='body'>
        <DialogTitle>My Account</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <Grid container alignItems='center'>
                <Grid item xs={6}>
                  <Typography variant='h6' display='inline'>
                    Username
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='h5' display='inline'>
                    @{currentSession?.user?.telegramUsername}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant='h6' display='inline'>
                    Account created
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='h5' display='inline'>
                    {new Date(currentSession?.user?.createdAt * 1000).toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <MiniSpacer />
            <Divider />
            <MiniSpacer />
            <ListItem button onClick={() => setTermsDialogOpen(true)}>
              <ListItemIcon>
                <InfoOutlined />
              </ListItemIcon>
              <Typography variant='h6' display='inline'>
                Terms & Conditions&nbsp;
              </Typography>
            </ListItem>
            <ListItem button onClick={() => setPrivacyPolicyDialogOpen(true)}>
              <ListItemIcon>
                <SecurityOutlined />
              </ListItemIcon>
              <Typography variant='h6' display='inline'>
                Privacy Policy&nbsp;
              </Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button size='large' onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <TermsDialog open={termsDialogOpen} onClose={() => setTermsDialogOpen(false)} />
      <PrivacyPolicyDialog
        open={privacyPolicyDialogOpen}
        onClose={() => setPrivacyPolicyDialogOpen(false)}
      />
    </>
  )
}

export default withStyles(styles)(MyAccountDialog)
