import { useQuery } from '@apollo/client'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import { GET_APPLICANTS } from '../queries/queries'
import ReadyForPollDialog from './ReadyForPollDialog'
import ResponsiveDialog from './ResponsiveDialog'

const styles = (theme) => ({})

const Spacer = () => <div style={{ padding: 32 }} />

const ReadyForPoll = () => {
  const [applicant, setApplicant] = useState(null)
  const { data, error, loading } = useQuery(GET_APPLICANTS, {
    variables: { status: 'approved' },
  })

  if (loading) return <CircularProgress />
  if (error) return <Typography variant='h6'>ERROR</Typography>
  const ready = data.applicants
  if (ready.length === 0) return <Typography variant='h6'>None</Typography>
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {ready.map((item) => (
          <Grid item xs={6} lg={4} key={item.id}>
            <Card>
              <CardContent>
                <Typography variant='subtitle1'>
                  {item.name}
                  <br />
                  (@{item.telegramUsername.replace('@', '')})
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => setApplicant(item)}>View Application</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {applicant && (
        <ResponsiveDialog open={open} onClose={() => setApplicant(null)}>
          <ReadyForPollDialog applicant={applicant} onClose={() => setApplicant(null)} />
        </ResponsiveDialog>
      )}
    </React.Fragment>
  )
}

export default ReadyForPoll
