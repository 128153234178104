import { SET_PAGE_TITLE } from "./mainActions";

const initialState = {
  pageTitle: "",
};

function reducer(state, action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return { ...state, pageTitle: action.pageTitle };

    default:
      throw new Error();
  }
}

export { initialState, reducer };
