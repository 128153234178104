import gql from 'graphql-tag'

export const GET_PARTICIPATION_TRACKING = gql`
  query ParticipationTrackings {
    participationTrackings {
      id
      telegramId
      telegramUsername
      user {
        id
        name
      }
      accountType
      lastPoll
    }
  }
`
export const GET_POLL = gql`
  query Poll {
    poll {
      id
      limit
      applicants {
        id
      }
    }
  }
`

export const GET_VOTE_PAGES = gql`
  query VotePages {
    votePages {
      id
      applicantId
      applicantName
      vote {
        id
        body
        choice
      }
    }
  }
`

export const GET_FEEDBACK = gql`
  query Feedback {
    feedback {
      id
      body
    }
  }
`

export const GET_FEEDBACKS = gql`
  query Feedbacks {
    feedbacks {
      id
      user {
        id
        name
      }
      body
    }
  }
`

export const GET_POLL_RESULTS = gql`
  query PollResults {
    pollResults {
      id
      result
      yes
      no
      negative
      positive
      applicant {
        id
        name
        telegramUsername
        telegramId
        votes {
          id
          user {
            id
            name
          }
          body
          choice
        }
      }
    }
  }
`

export const GET_POLL_RESULTS_MID_POLL = gql`
  query PollResultsMidPoll {
    pollResults: midPollResults {
      applicant {
        id
        name
        telegramUsername
      }
      yes
      no
      negative
      positive
      votesTotal
    }
  }
`

export const GET_APPLICANTS = gql`
  query Applicants($status: String!) {
    applicants(status: $status) {
      id
      name
      telegramId
      telegramUsername
      age
      otherNames
      referral
      referralHowKnow
      bio
      fursuit
      modded
      additionalInfo
      socialAccount {
        id
        tw
        ad
        ph
        xt
        fa
        sf
      }
      hasTestified
      status
    }
  }
`

export const GET_APPLICANT = gql`
  query Applicant($id: ID!) {
    applicant(id: $id) {
      id
      name
      telegramUsername
      age
      otherNames
      referral
      referralHowKnow
      bio
      fursuit
      modded
      additionalInfo
      status
      socialAccount {
        id
        tw
        ad
        ph
        xt
        fa
        sf
      }
      media {
        id
        file
      }
    }
  }
`

export const GET_APPLICANT_BY_USER = gql`
  query ApplicantByUser($telegramId: ID!) {
    applicantByUser(telegramId: $telegramId) {
      id
      name
      telegramUsername
      age
      otherNames
      referral
      referralHowKnow
      bio
      fursuit
      modded
      additionalInfo
      status
      socialAccount {
        id
        tw
        ad
        ph
        xt
        fa
        sf
      }
      media {
        id
        file
      }
    }
  }
`

export const GET_APPLICANT_MEDIA = gql`
  query ApplicantMedia($applicantId: ID!) {
    applicantMedia(applicantId: $applicantId) {
      id
      file
    }
  }
`

export const GET_VOTE_COMMENTS = gql`
  query VoteComments($applicantId: ID!) {
    voteComments(applicantId: $applicantId) {
      id
      choice
      user {
        id
        name
      }
      body
    }
  }
`

export const GET_APPLICANT_TESTIMONIALS = gql`
  query ApplicantTestimonials($applicantId: ID!) {
    applicantTestimonials(applicantId: $applicantId) {
      id
      body
      user {
        id
        name
      }
    }
  }
`
