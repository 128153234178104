import gql from 'graphql-tag'

export const CREATE_APPLICANT = gql`
  mutation createApplicant($input: CreateApplicantInput!) {
    createApplicant(input: $input) {
      applicant {
        id
        name
        telegramId
        telegramUsername
        age
        otherNames
        referral
        referralHowKnow
        bio
        fursuit
        modded
        additionalInfo
        hasTestified
        status
        socialAccount {
          id
          tw
          ad
          ph
          xt
          fa
          sf
        }
      }
      errors
    }
  }
`

export const UPDATE_APPLICANT = gql`
  mutation updateApplicant($input: UpdateApplicantInput!) {
    updateApplicant(input: $input) {
      applicant {
        id
        name
        telegramUsername
        telegramId
        age
        otherNames
        referral
        referralHowKnow
        bio
        fursuit
        modded
        additionalInfo
        status
        socialAccount {
          id
          tw
          ad
          ph
          xt
          fa
          sf
        }
      }
      errors
    }
  }
`

export const UPDATE_APPLICANT_STATUS = gql`
  mutation updateApplicantStatus($input: UpdateApplicantStatusInput!) {
    updateApplicantStatus(input: $input) {
      applicant {
        id
        name
        telegramUsername
        telegramId
        age
        otherNames
        referral
        referralHowKnow
        bio
        fursuit
        modded
        additionalInfo
        hasTestified
        status
        socialAccount {
          id
          tw
          ad
          ph
          xt
          fa
          sf
        }
      }
      errors
    }
  }
`

export const CREATE_MEDIUM = gql`
  mutation createMedium($input: CreateMediumInput!) {
    createMedium(input: $input) {
      medium {
        id
        file
      }
      errors
    }
  }
`

export const CREATE_TESTIMONIAL = gql`
  mutation createTestimonial($input: CreateTestimonialInput!) {
    createTestimonial(input: $input) {
      testimonial {
        id
      }
      errors
    }
  }
`

export const CREATE_POLL = gql`
  mutation createPoll($input: CreatePollInput!) {
    createPoll(input: $input) {
      poll {
        id
        limit
        applicants {
          id
        }
      }
      errors
    }
  }
`

export const CREATE_VOTE = gql`
  mutation createVote($input: CreateVoteInput!) {
    createVote(input: $input) {
      vote {
        id
        applicantId
        choice
        body
      }
      errors
    }
  }
`

export const CREATE_FEEDBACK = gql`
  mutation createFeedback($input: CreateFeedbackInput!) {
    createFeedback(input: $input) {
      feedback {
        id
      }
      errors
    }
  }
`

export const CLOSE_POLL = gql`
  mutation closePoll($input: ClosePollInput!) {
    closePoll(input: $input) {
      poll {
        id
      }
      errors
    }
  }
`

export const DELETE_MEDIUM = gql`
  mutation deleteMedium($input: DeleteMediumInput!) {
    deleteMedium(input: $input) {
      errors
    }
  }
`

export const DELETE_TESTIMONIAL = gql`
  mutation deleteTestimonial($input: DeleteTestimonialInput!) {
    deleteTestimonial(input: $input) {
      id
      errors
    }
  }
`
