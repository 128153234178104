import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { CREATE_VOTE } from '../queries/mutations'
import { GET_APPLICANT, GET_VOTE_PAGES } from '../queries/queries'
import ApplicationReviewContent from './ApplicationReviewContent'
import { MediumSpacer } from './Spacers'
import { getVoteLabel, voteLabels } from '../consts/voteLabels'

const styles = (theme) => ({
  danger: {
    color: theme.palette.danger.main,
  },
})

const VotePage = ({ votePage, pollId, next, prev, classes }) => {
  const [body, setBody] = useState('')
  const [voteError, setVoteError] = useState(null)
  const [currentChoice, setCurrentChoice] = useState('')
  const { data, loading, error } = useQuery(GET_APPLICANT, {
    variables: {
      id: votePage.applicantId,
    },
  })
  const [createVote, { loading: createVoteLoading }] = useMutation(CREATE_VOTE, {
    onError: (e) => {
      console.log(e), setVoteError(e)
    },
    onCompleted: () => next(),
    update: (cache, { data: { createVote } }) => {
      let { votePages } = cache.readQuery({
        query: GET_VOTE_PAGES,
      })
      cache.writeQuery({
        query: GET_VOTE_PAGES,
        data: {
          votePages: votePages.map((a) => {
            return a.vote && a.vote.id === createVote.vote.id
              ? {
                  ...a,
                  vote: { ...a.vote, choice: createVote.vote.choice, body: createVote.vote.body },
                }
              : a.applicantId === createVote.vote.applicantId
              ? {
                  ...a,
                  vote: { ...createVote.vote, body: createVote.vote.body || '' },
                }
              : { ...a }
          }),
        },
      })
    },
  })

  React.useEffect(() => {
    setCurrentChoice(votePage.vote?.choice)
    setBody(votePage.vote?.body)
  }, [votePage])

  if (loading)
    return (
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    )

  if (error || !data || !data.applicant)
    return (
      <DialogContent>
        <Typography>ERROR</Typography>
      </DialogContent>
    )

  return (
    <React.Fragment>
      <DialogContent>
        <ApplicationReviewContent applicant={data.applicant} />
        <MediumSpacer />
        <Typography variant='h5' color='secondary'>
          Vote
        </Typography>
        <Divider />
        <MediumSpacer />
        <Typography variant='subtitle1'>
          Add a comment if you want. For HARD YES and HARD NO votes, it is mandatory to give a
          reason.
        </Typography>
        {voteError && (
          <Typography variant='h6' className={classes.danger}>
            You must provide a reason for HARD YES and HARD NO votes!!
          </Typography>
        )}
        <TextField
          label='Add a comment...'
          name='body'
          variant='outlined'
          value={body}
          onChange={(e) => setBody(e.target.value)}
          margin='dense'
          fullWidth
          multiline
          rows={6}
          rowsMax={20}
        />
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        {voteLabels.map((label) => {
          if (createVoteLoading) {
            return <CircularProgress key={label.id} />
          }
          return (
            <Button
              key={label.id}
              variant={currentChoice === label.id ? 'contained' : undefined}
              color={currentChoice === label.id ? 'secondary' : undefined}
              onClick={() => {
                setVoteError(null)
                createVote({
                  variables: {
                    input: {
                      applicantId: data.applicant.id,
                      pollId,
                      choice: label.id,
                      body,
                    },
                  },
                })
              }}
            >
              {label.label}
            </Button>
          )
        })}
      </DialogActions>
    </React.Fragment>
  )
}

export default withStyles(styles)(VotePage)
