import { useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  TableCell,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import YesIcon from '@material-ui/icons/Check'
import NoIcon from '@material-ui/icons/Clear'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaybeIcon from '@material-ui/icons/HelpOutline'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { GET_POLL_RESULTS, GET_POLL_RESULTS_MID_POLL } from '../queries/queries'

const alertStyles = (theme) => ({
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const styles = (theme) => ({
  grid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  yesIcon: {
    fontSize: 32,
    color: '#00ff99',
  },
  noIcon: {
    fontSize: 32,
    color: '#ff0066',
  },
  maybeIcon: {
    fontSize: 32,
    color: '#ffff99',
  },
})

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: 'center',
  },
  body: {
    fontSize: 16,
    textAlign: 'center',
  },
}))(TableCell)
const StyledUserTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell)

const PollResultsTable = ({ classes, withResults, setCommentsDialog }) => {
  const { loading, error, data } = useQuery(
    withResults ? GET_POLL_RESULTS : GET_POLL_RESULTS_MID_POLL
  )

  if (loading) return <CircularProgress />
  if (error || !data || !data.pollResults) {
    console.log(error, data)
    return <Typography>ERROR</Typography>
  }
  if (data.pollResults.length === 0)
    return (
      <Typography>
        <br />
        <br />
        Nothing to show
      </Typography>
    )

  return (
    <>
      {data.pollResults.map((result) => (
        <div style={{ width: '100%' }} key={result.applicant.name}>
          <ExpansionPanel style={{ width: '100%' }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>
                {result.applicant.name} - @{result.applicant.telegramUsername}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ width: '100%' }}>
              <Grid container className={classes.grid} alignItems='center'>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>Votes total</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>{result.votesTotal}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <hr />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>Hard No</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>{result.no}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>No</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>{result.negative}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>Yes</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>{result.positive}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>Hard Yes</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>{result.yes}</Typography>
                </Grid>

                {withResults && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>Result</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {result.result === 'Yes' ? (
                        <YesIcon className={classes.yesIcon} />
                      ) : result.result === 'No' ? (
                        <NoIcon className={classes.noIcon} />
                      ) : (
                        <MaybeIcon className={classes.maybeIcon} />
                      )}
                    </Grid>
                  </>
                )}

                {withResults && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>View Comments</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant='outlined'
                        onClick={() => setCommentsDialog(result.applicant)}
                      >
                        Comments
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      ))}
    </>
  )
}

export default withStyles(styles)(withRouter(withWidth()(PollResultsTable)))
