import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import ApplicationReviewContent from './ApplicationReviewContent'
import TestimonialDialog from './TestimonialDialog'

const styles = (theme) => ({})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const ReadyForPollDialog = ({ applicant, setAlertDialog, onClose }) => {
  const [testimonialDialog, setTestimonialDialog] = useState(false)
  const [testified, setTestified] = useState(false)

  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Application from {applicant.name}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ApplicationReviewContent applicant={applicant} />
      </DialogContent>
      <DialogActions>
        {!applicant.hasTestified && !testified && (
          <Button onClick={() => setTestimonialDialog(true)}>Leave a testimonial</Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      {testimonialDialog && (
        <TestimonialDialog
          applicant={applicant}
          setTestified={() => setTestified(true)}
          open={testimonialDialog}
          onClose={() => setTestimonialDialog(false)}
        />
      )}
    </React.Fragment>
  )
}

export default ReadyForPollDialog
