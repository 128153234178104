import { useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { UPDATE_APPLICANT_STATUS } from '../queries/mutations'
import { GET_APPLICANTS } from '../queries/queries'
import ApplicationReviewContent from './ApplicationReviewContent'

const styles = (theme) => ({})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const AdminApplicationReview = ({ applicant, setAlertDialog, onClose }) => {
  const [updateApplicant, { loading: updateApplicantLoading }] = useMutation(
    UPDATE_APPLICANT_STATUS,
    {
      update: (cache, { data: { updateApplicantStatus } }) => {
        let { applicants: pending } = cache.readQuery({
          query: GET_APPLICANTS,
          variables: { status: 'awaiting_review' },
        })
        cache.writeQuery({
          query: GET_APPLICANTS,
          variables: { status: 'awaiting_review' },
          data: {
            applicants: [...pending.filter((a) => a.id !== updateApplicantStatus.applicant.id)],
          },
        })
        let { applicants: newStatus } = cache.readQuery({
          query: GET_APPLICANTS,
          variables: { status: updateApplicantStatus.applicant.status },
        })
        cache.writeQuery({
          query: GET_APPLICANTS,
          variables: { status: updateApplicantStatus.applicant.status },
          data: { applicants: [...newStatus, updateApplicantStatus.applicant] },
        })
      },
    }
  )

  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Review Application
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ApplicationReviewContent applicant={applicant} />
      </DialogContent>
      <DialogActions>
        {[
          { value: 'approved', name: 'Approve' },
          { value: 'pending', name: 'Reject' },
        ].map((item) =>
          updateApplicantLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={() =>
                updateApplicant({
                  variables: {
                    input: {
                      id: applicant.id,
                      status: item.value,
                    },
                  },
                }).then(({ data }) => {
                  onClose()
                })
              }
            >
              {item.name}
            </Button>
          )
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(AdminApplicationReview)))
