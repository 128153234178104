import { useMutation, useQuery } from '@apollo/client'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import dateFormat from 'dateformat'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CREATE_POLL } from '../queries/mutations'
import { GET_APPLICANTS, GET_POLL } from '../queries/queries'
import AuthorizedUsers from './AuthorizedUsers'
import AwaitingReview from './AwaitingReview'
import NewApplicantDialog from './NewApplicantDialog'
import PageTitle from './PageTitle'
import ReadyForPoll from './ReadyForPoll'
import { MiniSpacer } from './Spacers'
import MidPollResultsDialog from './MidPollResultsDialog'

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexGrow: 1,
    maxHeight: 90,
    margin: '0 auto 16px auto',
  },
  centeredDiv: {
    textAlign: 'center',
  },
  grid: {
    textAlign: 'center',
    maxHeight: 90,
  },
  buttonBackground: {
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    height: 75,
    backgroundColor: '#222',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0 auto 16px auto',
    borderRadius: 100,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonBackgroundNoHover: {
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    margin: '0 auto 16px auto',
  },
  titlePadder: {
    paddingLeft: theme.spacing(3),
  },
  lowerRoot: {
    padding: theme.spacing(2),
  },
})

const Spacer = () => <div style={{ padding: 8 }} />
const DoubleSpacer = () => <div style={{ padding: 24 }} />

const AdminPanel = ({ classes, width, history }) => {
  const [pollDialog, setPollDialog] = useState(false)
  const [midPollResultsDialog, setMidPollResultsDialog] = useState(false)
  const [newApplicantDialog, setNewApplicantDialog] = useState(false)
  const { loading, error, data } = useQuery(GET_POLL)
  const [createPoll, { loading: createPollLoading }] = useMutation(CREATE_POLL, {
    update: (cache, { data: { createPoll } }) => {
      cache.writeQuery({
        query: GET_POLL,
        data: { poll: { ...createPoll.poll } },
      })
      cache.writeQuery({
        query: GET_APPLICANTS,
        variables: { status: 'approved' },
        data: { applicants: [] },
      })
    },
  })

  const newApplicantButton = (
    <div className={classes.buttonBackground} onClick={() => setNewApplicantDialog(true)}>
      <Typography variant='h4'>New Applicant</Typography>
    </div>
  )

  let creatingPollButton = undefined
  let createPollButton = undefined
  let pollEndedButton = undefined
  let ongoingPollButton = undefined
  let viewPollResultsButton = undefined

  if (!data || !data.poll) {
    if (createPollLoading) {
      creatingPollButton = (
        <div className={classes.buttonBackground}>
          <CircularProgress />
          <Typography variant='h4'>Creating Poll</Typography>
        </div>
      )
    } else {
      createPollButton = (
        <div
          className={classes.buttonBackground}
          onClick={() =>
            createPoll({
              variables: {
                input: {
                  limit: null,
                },
              },
            })
          }
        >
          <Typography variant='h4'>Start Poll</Typography>
        </div>
      )
    }
  } else {
    if (data.poll.limit * 1000 < Date.now()) {
      pollEndedButton = (
        <div className={classes.buttonBackgroundNoHover}>
          <Typography variant='h5'>Poll Ended (Results Below)</Typography>
        </div>
      )
    } else {
      ongoingPollButton = (
        <>
          <div className={classes.buttonBackgroundNoHover}>
            <Typography variant='h5'>
              Ongoing Poll
              <br />
            </Typography>
          </div>
          <div style={{ marginTop: -40 }}>
            <Typography variant='h6' color='secondary'>
              <br />
              Current poll ends {dateFormat(new Date(data.poll.limit * 1000), 'mmmm dS, yyyy')}
            </Typography>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setMidPollResultsDialog(true)}
            >
              Check results so far
            </Button>
          </div>
        </>
      )
    }
  }

  if (data && data.poll && data.poll.limit * 1000 < Date.now()) {
    viewPollResultsButton = (
      <div className={classes.buttonBackground} onClick={() => history.push('/poll_results')}>
        <Typography variant='h4'>View Poll Results</Typography>
      </div>
    )
  }

  return (
    <React.Fragment>
      <PageTitle title='Admin Panel' />
      <div className={classes.centeredDiv}>{newApplicantButton}</div>
      <div className={classes.centeredDiv}>
        {creatingPollButton}
        {createPollButton}
        {pollEndedButton}
        {ongoingPollButton}
      </div>
      <div className={classes.centeredDiv}>{viewPollResultsButton}</div>
      <MiniSpacer />
      <div className={classes.lowerRoot}>
        <Typography variant='h4' className={classes.titlePadder}>
          Authorized Users
        </Typography>
        <Typography variant='subtitle1' className={classes.lightTypo}>
          Users that have been added to the Telegram Login approval list. They can now login and
          start their application
        </Typography>
        <Spacer />
        <AuthorizedUsers />
        <DoubleSpacer />
        <Typography variant='h4' className={classes.titlePadder}>
          Awaiting Review
        </Typography>
        <Typography variant='subtitle1' className={classes.lightTypo}>
          These applications have been filled and are awaiting admin approval to go to the next poll
        </Typography>
        <Spacer />
        <AwaitingReview />
        <DoubleSpacer />
        <Typography variant='h4' className={classes.titlePadder}>
          Going in next poll
        </Typography>
        <Typography variant='subtitle1' className={classes.lightTypo}>
          These applications are approved and will be live in the next poll
        </Typography>
        <Spacer />
        <ReadyForPoll />
      </div>
      <DoubleSpacer />
      {newApplicantDialog && (
        <NewApplicantDialog
          open={newApplicantDialog}
          onClose={() => setNewApplicantDialog(false)}
        />
      )}
      {midPollResultsDialog && (
        <MidPollResultsDialog
          open={midPollResultsDialog}
          onClose={() => setMidPollResultsDialog(false)}
        />
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(AdminPanel)))
