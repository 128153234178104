import { useQuery, useMutation } from '@apollo/client'
import { CircularProgress, Divider, Grid, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { GET_APPLICANT_TESTIMONIALS } from '../queries/queries'
import { MediumSpacer, MiniSpacer, SuperSpacer } from './Spacers'
import useCurrentSession from './useCurrentSession'
import { DELETE_TESTIMONIAL } from '../queries/mutations'

const styles = (theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const TestimonialsListDialog = ({ applicant, classes }) => {
  const [filter, setFilter] = useState('noFilter')
  const { loading, error, data } = useQuery(GET_APPLICANT_TESTIMONIALS, {
    variables: { applicantId: applicant.id },
  })
  const currentSession = useCurrentSession()
  const [deleteTestimonial] = useMutation(DELETE_TESTIMONIAL, {
    update: (cache, { data: { deleteTestimonial } }) => {
      const { applicantTestimonials } = cache.readQuery({
        query: GET_APPLICANT_TESTIMONIALS,
        variables: { applicantId: applicant.id },
      })
      cache.writeQuery({
        query: GET_APPLICANT_TESTIMONIALS,
        variables: { applicantId: applicant.id },
        data: {
          applicantTestimonials: applicantTestimonials.filter((a) => a.id !== deleteTestimonial.id),
        },
      })
    },
  })

  let testimonials = null
  if (data && data.applicantTestimonials) {
    testimonials = data.applicantTestimonials
  }
  return (
    <>
      <Grid container spacing={0} alignItems='center' justify='space-between'>
        <Grid item>
          <Typography variant='h5' noWrap color='secondary'>
            Testimonials for {applicant.name}
          </Typography>
        </Grid>
      </Grid>
      {error && <Typography>Error</Typography>}
      {(loading || !data) && <CircularProgress />}
      {testimonials && testimonials.length === 0 && (
        <>
          <Typography>Nothing to show</Typography>
          <MediumSpacer />
        </>
      )}
      {testimonials &&
        testimonials.map((testimonial) => (
          <div className={classes.flex} key={testimonial.id}>
            <div>
              {testimonial.body && (
                <>
                  <Divider />
                  <MiniSpacer />
                  <Typography variant='subtitle1'>
                    <i>
                      Testimonial by <strong>{testimonial.user.name}</strong>
                    </i>
                  </Typography>
                  <Typography variant='h6' style={{ paddingLeft: 16, paddingTop: 8 }}>
                    {testimonial.body}
                  </Typography>
                  <SuperSpacer />
                </>
              )}
            </div>
            <div>
              {currentSession?.user.isAdmin && (
                <Button
                  variant='outlined'
                  onClick={() => {
                    deleteTestimonial({ variables: { input: { id: testimonial.id } } })
                  }}
                >
                  DELETE
                </Button>
              )}
            </div>
          </div>
        ))}
    </>
  )
}

export default withStyles(styles)(TestimonialsListDialog)
