import React from 'react'
import { MainContext } from '../contexts/MainContext'
import { setPageTitle } from '../services/mainActions'

const PageTitle = ({ title }) => {
  const { dispatch } = React.useContext(MainContext)

  React.useEffect(() => {
    dispatch(setPageTitle(title))
    document.title = `Shag ${title}`
  }, [title])

  return null
}

export default PageTitle
