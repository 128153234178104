import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const httpLink = createHttpLink({
  uri: '/graphql',
})
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Huff ${cookies.get('csrf-token')}`,
      'X-CSRF-Token': cookies.get('csrf-token'),
    },
  }
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})

export default apolloClient
