import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { CREATE_VOTE, CREATE_FEEDBACK } from '../queries/mutations'
import { GET_APPLICANT, GET_FEEDBACK } from '../queries/queries'
import ApplicationReviewContent from './ApplicationReviewContent'

const styles = (theme) => ({
  danger: {
    color: theme.palette.danger.main,
  },
})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const FeedbackPage = ({ next, classes }) => {
  const [body, setBody] = useState('')
  const { data, loading, error } = useQuery(GET_FEEDBACK)

  const [
    updateOrCreateFeedback,
    { error: updateOrCreateFeedbackError, loading: updateOrCreateFeedbackLoading },
  ] = useMutation(CREATE_FEEDBACK)

  React.useEffect(() => {
    if (data?.feedback?.body) setBody(data.feedback.body)
  }, [data])

  return (
    <React.Fragment>
      <DialogContent>
        <Typography variant='subtitle1'>
          Please feel free to leave any feedback you may have regarding Shag Carpets here. The
          admins read every comment left here after each poll 😊
        </Typography>
        <TextField
          label='Feedback...'
          variant='outlined'
          name='body'
          value={body}
          onChange={(e) => setBody(e.target.value)}
          margin='dense'
          fullWidth
          multiline
          rows={8}
          rowsMax={20}
        />
      </DialogContent>
      <DialogActions>
        {updateOrCreateFeedbackLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              updateOrCreateFeedback({
                variables: {
                  input: {
                    body,
                  },
                },
              }).then(() => {
                next()
              })
            }
          >
            Send
          </Button>
        )}
      </DialogActions>
    </React.Fragment>
  )
}

export default withStyles(styles)(FeedbackPage)
