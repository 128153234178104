import { useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import { withRouter } from 'react-router-dom'
import TelegramLoginButton from 'react-telegram-login'
import { CREATE_SESSION } from '../queries/globalQueries'

const styles = (theme) => ({
  titleBarContainer: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  link: {
    color: theme.palette.text.primary,
  },
  loginButtonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  loginButton: {
    zIndex: 1,
    position: 'relative',
    minHeight: 48,
  },
  telegramLoader: {
    position: 'absolute',
    left: '50%',
    top: 0,
    marginLeft: -16,
  },
  blurbDanger: {
    color: theme.palette.danger.main,
  },
  warning: {
    color: theme.palette.danger.main,
  },
  dialogContentTop: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
})

const SignUpDialog = ({ classes, open, onClose, loading, width }) => {
  const [unauthorized, setUnauthorized] = React.useState(false)
  const [createSession, { loading: submitting }] = useMutation(CREATE_SESSION, {
    onCompleted: (data) => {
      location.reload()
    },
    onError: () => setUnauthorized(true),
  })

  return (
    <React.Fragment>
      {((width !== 'lg' && width !== 'xl') || true) && (
        <DialogTitle className={classes.titleBarContainer}>
          <Grid container spacing={0} alignItems='center' justify='space-between'>
            <Grid item>
              <Typography variant='h6' noWrap color={'inherit'}>
                Login with Telegram
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      {unauthorized && !submitting && (
        <DialogContent>
          <Typography variant='h4' className={classes.blurbDanger}>
            {`UNAUTHORIZED`}
          </Typography>
        </DialogContent>
      )}
      {!unauthorized && (
        <DialogContent>
          <div className={classes.dialogContentTop}>
            <Typography variant='h6'>
              {`Shag Carpets utilises `}
              <a
                href='https://telegram.org/blog/login'
                target='_blank'
                rel='noopener'
                className={classes.link}
              >
                Telegram Login for Websites
              </a>
              {` to allow users to easily sign-in and to verify whether they are allowed to use this app or not.`}
            </Typography>
            <br />
            <Typography variant='h6'>Please note that:</Typography>
          </div>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary='Telegram will share your username, handle, avatar, bio and ID.'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary='Telegram will NOT share your phone number, contacts and messages.'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary='A unique session identifier will be stored in your browser to keep you connected.'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon classes={{ root: classes.warning }}>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText
                inset
                classes={{ primary: classes.warning }}
                primary='Telegram uses Cookies to log you in. Please make sure that Third-Party Cookies are not blocked on your browser.'
              />
            </ListItem>
          </List>
          {
            <div className={classes.loginButtonContainer}>
              <div className={classes.loginButton}>
                {!submitting &&
                  (process.env.NODE_ENV === 'production' ? (
                    <TelegramLoginButton
                      dataOnauth={(response) =>
                        createSession({
                          variables: {
                            input: {
                              telegramId: response.id.toString(),
                              telegramFirstName: response.first_name,
                              telegramLastName: response.last_name,
                              telegramUsername: response.username,
                              telegramAuthDate: response.auth_date,
                              telegramPhotoUrl: response.photo_url,
                              telegramHash: response.hash,
                            },
                          },
                        })
                      }
                      botName={`${process.env.TELEGRAM_LOGIN_BOT_NAME}`}
                    />
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          createSession({
                            variables: {
                              input: {
                                telegramId: 0,
                                telegramFirstName: 'ADMIN USER',
                                telegramUsername: 'AdminUser',
                                telegramAuthDate: 123456789,
                                telegramPhotoUrl: 'https://t.me/i/userpic/320/Coontail.jpg',
                                telegramHash:
                                  '1c7bc6a02407b952d8c521f151b07a338834e62394e78ea5e5a1863e13c63993',
                              },
                            },
                          })
                        }}
                        variant='contained'
                      >
                        Login as Admin
                      </Button>
                      <Button
                        onClick={() => {
                          createSession({
                            variables: {
                              input: {
                                telegramId: 5,
                                telegramFirstName: 'MEMBER USER',
                                telegramUsername: 'MemberUser',
                                telegramAuthDate: 123456789,
                                telegramPhotoUrl: 'https://t.me/i/userpic/320/Coontail.jpg',
                                telegramHash:
                                  '1c7bc6a02407b952d8c521f151b07a338834e62394e78ea5e5a1863e13c63993',
                              },
                            },
                          })
                        }}
                        variant='contained'
                      >
                        Login as Member
                      </Button>
                      <Button
                        onClick={() => {
                          createSession({
                            variables: {
                              input: {
                                telegramId: 32,
                                telegramFirstName: 'APPLI USER',
                                telegramUsername: 'AppliUser',
                                telegramAuthDate: 123456789,
                                telegramPhotoUrl: 'https://t.me/i/userpic/320/Coontail.jpg',
                                telegramHash:
                                  '1c7bc6a02407b952d8c521f151b07a338834e62394e78ea5e5a1863e13c63993',
                              },
                            },
                          })
                        }}
                        variant='contained'
                      >
                        Login as Applicant
                      </Button>
                    </>
                  ))}
              </div>
              <CircularProgress className={classes.telegramLoader} size={32} />
            </div>
          }
        </DialogContent>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(SignUpDialog)))
