import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { GET_SESSION } from '../queries/globalQueries'

export default () => {
    const { data, loading, error } = useQuery(GET_SESSION)

    if (loading || !data) return null
    if (data.session) {
        const user = data.session.user
        Sentry.configureScope(function(scope) {
            scope.setUser({
                id: user.id,
                username: user.telegramUsername,
                telegramId: user.telegramId,
            })
        })
    }
    return data.session
}
