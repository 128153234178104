import gql from 'graphql-tag'

export const GET_THEME = gql`
  query getTheme @client {
    theme
  }
`

export const DELETE_SESSION = gql`
  mutation deleteSession($input: DeleteSessionInput!) {
    deleteSession(input: $input) {
      errors
    }
  }
`

export const CREATE_SESSION = gql`
  mutation createSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      session {
        id
        user {
          id
          telegramId
          telegramUsername
        }
      }
    }
  }
`

export const GET_SESSION = gql`
  query Session {
    session {
      id
      user {
        id
        telegramId
        telegramUsername
        name
        isAdmin
        isMember
        isApplicant
        createdAt
      }
    }
  }
`
