import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ResponsiveDialog from './ResponsiveDialog'
import { MiniSpacer } from './Spacers'
import { useQuery } from '@apollo/client'
import { GET_POLL_RESULTS } from '../queries/queries'
import PollResultsTable from './PollResultsTable'

const styles = (theme) => ({
  separator: {
    textAlign: 'center',
    width: '80%',
  },
})

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: 'center',
  },
  body: {
    fontSize: 16,
    textAlign: 'center',
  },
}))(TableCell)
const StyledUserTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell)

const MidPollResultsDialog = ({ open, onClose, classes }) => {
  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Ongoing Poll Results
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6'>Here are the compiled poll results so far</Typography>
        <PollResultsTable />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default withStyles(styles)(withRouter(withWidth()(MidPollResultsDialog)))
