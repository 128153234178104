import { useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CREATE_APPLICANT } from '../queries/mutations'
import { GET_APPLICANTS } from '../queries/queries'
import ResponsiveDialog from './ResponsiveDialog'

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const styles = (theme) => ({
  separator: {
    textAlign: 'center',
    width: '80%',
  },
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const NewApplicantDialog = ({ classes, open, onClose }) => {
  const [alreadyExists, setAlreadyExists] = useState(false)
  const [telegramId, setTelegramId] = useState('')
  const [telegramUsername, setTelegramUsername] = useState('')
  const [createApplicant, { loading: createApplicantLoading, data: mutationData }] = useMutation(
    CREATE_APPLICANT,
    {
      update: (cache, { data: { createApplicant } }) => {
        const { applicants } = cache.readQuery({
          query: GET_APPLICANTS,
          variables: { status: 'pending' },
        })
        cache.writeQuery({
          query: GET_APPLICANTS,
          variables: { status: 'pending' },
          data: { applicants: [createApplicant.applicant, ...applicants] },
        })
      },
      onCompleted: (e) => onClose(),
    }
  )

  React.useEffect(() => {
    if (mutationData && mutationData.createApplicant.errors.length > 0) {
      setAlreadyExists(true)
    }
  }, [mutationData])

  return (
    <React.Fragment>
      <ResponsiveDialog open={open} onClose={onClose}>
        <DialogTitle>
          <Grid container spacing={0} alignItems='center' justify='space-between'>
            <Grid item>
              <Typography variant='h6' noWrap color={'inherit'}>
                New Applicant
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            Here you can add the telegram data for someone who wishes to apply to Shag Carpets.
            <br />
            <br />
            Adding them will grant them access to this site and allow them to fill the application
            form.
            <br />
            <br />
            The Telegram ID can be found by forwarding one of their messages to @UserInfoBot on
            Telegram, or by checking their profile on Plus Messenger.
          </Typography>
          <Spacer />
          <hr className={classes.separator} />
          <Spacer />
          <TextField
            label='Telegram Username'
            name='telegramUsername'
            value={telegramUsername}
            onChange={(e) => setTelegramUsername(e.target.value)}
            margin='dense'
            fullWidth
          />
          <TextField
            label='Telegram ID'
            name='telegramId'
            value={telegramId}
            onChange={(e) => setTelegramId(e.target.value)}
            margin='dense'
            fullWidth
          />
          {alreadyExists && (
            <React.Fragment>
              <Spacer />
              <Typography variant='subtitle1' className={classes.dangerButton}>
                Telegram ID has already been taken
              </Typography>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {createApplicantLoading ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={!telegramId || !telegramUsername}
              onClick={() => {
                setAlreadyExists(false)
                createApplicant({
                  variables: {
                    input: {
                      telegramId,
                      telegramUsername,
                    },
                  },
                }).then((e) => e.data && e.data.createApplicant.errors.length === 0 && onClose())
              }}
            >
              Add Applicant
            </Button>
          )}
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </ResponsiveDialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(NewApplicantDialog)))
