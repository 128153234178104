import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Button,
  TableRow,
  Typography,
} from '@material-ui/core'
import { KeyboardArrowDown as DownIcon, KeyboardArrowUp as UpIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { GET_PARTICIPATION_TRACKING } from '../queries/queries'

const alertStyles = (theme) => ({
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexGrow: 1,
    maxHeight: 90,
  },
  grid: {
    textAlign: 'center',
    maxHeight: 90,
  },
  buttonBackground: {
    width: '40%',
    height: 75,
    backgroundColor: '#222',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    borderRadius: 100,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  yesIcon: {
    fontSize: 32,
    color: '#00ff99',
  },
  noIcon: {
    fontSize: 32,
    color: '#ff0066',
  },
  maybeIcon: {
    fontSize: 32,
    color: '#ffff99',
  },
  dangerCell: {
    color: theme.palette.secondary.main,
  },
})

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#222',
    color: theme.palette.common.white,
    fontSize: 20,
    cursor: 'pointer',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell)

const tableSort = (sort) => (a, b) => {
  if (sort.column === 'name') {
    if (a.user === null) return 1
    if (b.user === null) return -1
    if (a.user.name.toUpperCase() === b.user.name.toUpperCase()) return 0
    if (sort.direction === 'ASC')
      return a.user.name.toUpperCase() < b.user.name.toUpperCase() ? -1 : 1
    if (sort.direction === 'DESC')
      return a.user.name.toUpperCase() < b.user.name.toUpperCase() ? 1 : -1
  }
  if (sort.column === 'tgHandle') {
    if (a.user === null) return 1
    if (b.user === null) return -1
    if (a.telegramUsername.toUpperCase() === b.telegramUsername.toUpperCase()) return 0
    if (sort.direction === 'ASC')
      return a.telegramUsername.toUpperCase() < b.telegramUsername.toUpperCase() ? -1 : 1
    if (sort.direction === 'DESC')
      return a.telegramUsername.toUpperCase() < b.telegramUsername.toUpperCase() ? 1 : -1
  }
  if (sort.column === 'tgId') {
    if (parseInt(a.telegramId) === parseInt(b.telegramId)) return 0
    if (sort.direction === 'ASC') return parseInt(a.telegramId) < parseInt(b.telegramId) ? -1 : 1
    if (sort.direction === 'DESC') return parseInt(a.telegramId) < parseInt(b.telegramId) ? 1 : -1
  }
  if (sort.column === 'accountType') {
    if (a.accountType === b.accountType) return 0
    if (sort.direction === 'ASC') return a.accountType < b.accountType ? -1 : 1
    if (sort.direction === 'DESC') return a.accountType < b.accountType ? 1 : -1
  }
  if (sort.column === 'lastPoll') {
    if (a.lastPoll === b.lastPoll) return 0
    if (sort.direction === 'ASC') return a.lastPoll < b.lastPoll ? -1 : 1
    if (sort.direction === 'DESC') return a.lastPoll < b.lastPoll ? 1 : -1
  }
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const ParticipationTrackingTableDesktop = ({ classes }) => {
  const { loading, error, data } = useQuery(GET_PARTICIPATION_TRACKING)
  const [sort, setSort] = useState({ column: 'name', direction: 'ASC' })
  const [participationData, setParticipationData] = useState([])

  const accountTypeRenderer = React.useCallback((type) => {
    if (type === 'Not Created')
      return <StyledTableCell className={classes.dangerCell}>{type}</StyledTableCell>
    else return <StyledTableCell>{type}</StyledTableCell>
  })

  const lastPollRenderer = React.useCallback((lastPoll) => {
    if (lastPoll === -1) return <StyledTableCell>New User</StyledTableCell>
    else if (!lastPoll)
      return <StyledTableCell className={classes.dangerCell}>Never Voted!</StyledTableCell>
    else
      return (
        <StyledTableCell>
          {monthNames[new Date(lastPoll * 1000).getMonth()]}{' '}
          {new Date(lastPoll * 1000).getFullYear()}
        </StyledTableCell>
      )
  })

  const sortIndicatorRenderer = React.useCallback((name) => {
    if (sort.column === name) {
      if (sort.direction === 'ASC')
        return (
          <>
            &nbsp;&nbsp;
            <DownIcon />
          </>
        )
      else
        return (
          <>
            &nbsp;&nbsp;
            <UpIcon />
          </>
        )
    } else return null
  })

  const sortTable = React.useCallback((e) => {
    let value = e.currentTarget.getAttribute('value')
    if (value !== sort.column) setSort({ column: value, direction: 'ASC' })
    else setSort({ column: sort.column, direction: sort.direction === 'ASC' ? 'DESC' : 'ASC' })
  })

  React.useEffect(() => {
    if (data && data.participationTrackings)
      setParticipationData([...data.participationTrackings].sort(tableSort(sort)))
  }, [sort, data])

  if (loading) return <CircularProgress />
  if (error || !data || !data.participationTrackings) return <Typography>ERROR</Typography>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell sort={sort} onClick={sortTable} value='tgId'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              TG ID
              {sortIndicatorRenderer('tgId')}
            </div>
          </StyledTableCell>
          <StyledTableCell sort={sort} onClick={sortTable} value='tgHandle'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              TG Handle
              {sortIndicatorRenderer('tgHandle')}
            </div>
          </StyledTableCell>
          <StyledTableCell sort={sort} onClick={sortTable} value='name'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Name
              {sortIndicatorRenderer('name')}
            </div>
          </StyledTableCell>
          <StyledTableCell sort={sort} onClick={sortTable} value='accountType'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Account Type
              {sortIndicatorRenderer('accountType')}
            </div>
          </StyledTableCell>
          <StyledTableCell sort={sort} onClick={sortTable} value='lastPoll'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Last Poll
              {sortIndicatorRenderer('lastPoll')}
            </div>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {participationData.map((result) => (
          <TableRow key={result.id}>
            <StyledTableCell>{result.telegramId}</StyledTableCell>
            <StyledTableCell>@{result.telegramUsername}</StyledTableCell>
            <StyledTableCell>{result.user?.name}</StyledTableCell>
            {accountTypeRenderer(result.accountType)}
            {lastPollRenderer(result.lastPoll)}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default withStyles(styles)(ParticipationTrackingTableDesktop)
