import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { withRouter } from 'react-router-dom'

const styles = (theme) => ({})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const ApplicationPartFour = ({ applicant, onClose }) => {
  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Thank you, {applicant.name}!
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6'>
          Your application has now been submitted for approval by our admins! You will be informed
          of the poll results as soon as they are available.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(ApplicationPartFour)))
