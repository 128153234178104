import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ResponsiveDialog from './ResponsiveDialog'
import VotePage from './VotePage'
import { GET_VOTE_PAGES } from '../queries/queries'
import { useQuery } from '@apollo/client'
import VoteReview from './VoteReview'
import goldStar from '../../../assets/images/goldStar.png'
import { MediumSpacer, MiniSpacer } from './Spacers'
import FeedbackPage from './FeedbackPage'

const styles = (theme) => ({
  centerImage: {
    textAlign: 'center',
  },
  image: {
    width: '80%',
  },
})

const VoteBlurb = ({ open, onClose, poll, classes }) => {
  return (
    <>
      <Typography variant='h5' color='secondary'>
        HOW TO VOTE
      </Typography>
      <MediumSpacer />
      <div style={{ paddingLeft: 24, paddingRight: 24 }}>
        <Typography variant='h6' display='inline'>
          <strong>Hard Yes</strong>
        </Typography>
        <Typography variant='subtitle1' display='inline'>
          {' '}
          - I know this person personally, very well. I can vouch that they are a good, trustworthy
          person.
          <br />
          <em>
            If an applicant gets 20 Hard Yes votes, they will be added regardless of how many
            positives they get, assuming there are 0 valid Hard No votes.
          </em>
        </Typography>

        <MediumSpacer />

        <Typography variant='h6' display='inline'>
          <strong>Yes</strong>
        </Typography>
        <Typography variant='subtitle1' display='inline'>
          {' '}
          - I liked their application, or we may have talked a few times, they seem cool/I like
          their content. They do not seem to be a threat to the group
          <br />
          <em>
            A person needs 50% or more of their votes to be 'Yes' to be added. 'Hard Yes' votes are
            counted as 3 Yes votes.
          </em>
        </Typography>

        <MediumSpacer />

        <Typography variant='subtitle1' display='inline'>
          {' '}
          - I do not know who they are/care if they are added or not.
        </Typography>

        <MediumSpacer />

        <Typography variant='h6' display='inline'>
          <strong>No</strong>
        </Typography>
        <Typography variant='subtitle1' display='inline'>
          {' '}
          - They seem a bit sketchy, not enough information for me to trust them. I don't want them
          added but do not deem them an actual threat.
          <br />
          <em>
            If someone receives 25% or more ‘No’ votes, they will NOT be added, even with enough
            ‘Yes’ votes. If the person received more than 40% ‘No’ votes, this overrides any ‘Hard
            Yes’ votes.
          </em>
        </Typography>

        <MediumSpacer />

        <Typography variant='h6' display='inline'>
          <strong>Hard No</strong>
        </Typography>
        <Typography variant='subtitle1' display='inline'>
          {' '}
          - I have personal experience with this person and I believe they are a threat to the group
          or someone in the group (Stalking, assault, leaking, etc.). Do not add them. Personal
          drama or rumors is NOT a valid reason to vote ‘Hard No.’ Not knowing someone is NOT a
          valid reason. Use ‘No’ or ‘Neutral’ for these! A single VALID Hard No will exclude an
          applicant and they may be banned from future polls.
        </Typography>

        <MediumSpacer />

        <Typography variant='h6' display='inline'>
          You are free to include comments for any voting option, but explanation/reason is required
          for ‘Hard Yes’ and ‘Hard No’ votes to be considered valid.
        </Typography>

        <MediumSpacer />
      </div>
    </>
  )
}

export default withStyles(styles)(withRouter(withWidth()(VoteBlurb)))
