import { useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { UPDATE_APPLICANT_STATUS } from '../queries/mutations'
import ApplicationReviewContent from './ApplicationReviewContent'

const styles = (theme) => ({
  separator: {
    textAlign: 'center',
    width: '80%',
  },
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const ApplicationPartOne = ({
  classes,
  applicant,
  goToInfo,
  goToMedia,
  setAlertDialog,
  allDone,
  adminButtons,
  onAdminClose,
}) => {
  const [updateApplicant, { loading: updateApplicantLoading }] = useMutation(
    UPDATE_APPLICANT_STATUS
  )

  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Review your Application
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={() => setAlertDialog(true)} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Typography variant='h6'>
            Here is your completed application. You can now review it and edit some things if you
            want before submitting it!
          </Typography>
          <Spacer />
          <hr className={classes.separator} />
          <Spacer />
        </React.Fragment>
        <ApplicationReviewContent applicant={applicant} />
      </DialogContent>
      <DialogActions>
        <Button onClick={goToInfo}>Back to Info</Button>
        <Button onClick={goToMedia}>Back to Media</Button>
        {updateApplicantLoading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={() =>
              updateApplicant({
                variables: {
                  input: {
                    id: applicant.id,
                    status: 'awaiting_review',
                  },
                },
              }).then(({ data }) => {
                allDone()
              })
            }
          >
            Looks Good!
          </Button>
        )}
        <Button className={classes.dangerButton} onClick={() => setAlertDialog(true)}>
          CANCEL
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(ApplicationPartOne)))
