import { useQuery } from '@apollo/client'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { MainContextProvider } from '../contexts/MainContext'
import { GET_SESSION } from '../queries/globalQueries'
import AdminPanel from './AdminPanel'
import Home from './Home'
import Layout from './Layout'
import Login from './Login'
import PollResults from './PollResults'
import ParticipationTrackingTableDesktop from './ParticipationTrackingTableDesktop'
import ParticipationTracking from './ParticipationTracking'

const styles = (theme) => ({})

const Padder = () => <div style={{ padding: 40 }} />
const MicroPadder = () => <div style={{ padding: 8 }} />

const AppRouter = () => {
  const { data, loading, error } = useQuery(GET_SESSION, { fetchPolicy: 'cache-first' })

  if (loading) return null

  return (
    <MainContextProvider>
      <BrowserRouter>
        <Switch location={location}>
          {data && data.session ? (
            <>
              <Layout />
              <Switch location={location}>
                <Route exact path='/' render={() => <Home />} />
                <Route
                  exact
                  path='/admin'
                  component={data.session.user.isAdmin ? () => <AdminPanel /> : () => <Home />}
                />
                <Route
                  exact
                  path='/poll_results'
                  component={data.session.user.isAdmin ? () => <PollResults /> : () => <Home />}
                />
                <Route
                  exact
                  path='/participation'
                  component={
                    data.session.user.isAdmin ? () => <ParticipationTracking /> : () => <Home />
                  }
                />
              </Switch>
            </>
          ) : (
            <Route exact path='/' component={() => <Login />} />
          )}
        </Switch>
      </BrowserRouter>
    </MainContextProvider>
  )
}

export default AppRouter
